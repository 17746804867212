/* * {
    -webkit-tap-highlight-color: transparent;
} */
/* 
::-webkit-scrollbar {
    -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
    width: 4px;
}
::-webkit-scrollbar:horizontal {
    height: 4px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: none;
}
::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: transparent;
} */

html {
    /* Forces rem font sizes to be based on this 16px size */
    font-size: 16px;
}

body {
    margin: 0px;
    padding: 0px;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}

#root {
    height: 100%;
}

/* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */
